<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Mostrando</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
          >
            Exportar
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="seccionFilterSel"
              :reduce="m => m.value"
              label="text"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="seccionesFilter"
              class="invoice-filter-select mr-1"
              placeholder="Seleccione Tipo"
            >
              <template #selected-option="{ text }">
                <span class="text-truncate overflow-hidden">
                  {{ text }}
                </span>
              </template>
            </v-select>
            <flat-pickr
              id="fecha"
              v-model="fechaFilter"
              class="form-control"
              :config="configDP"
              placeholder="Seleccione Fecha"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refListTable"
      :items="items"
      responsive
      :fields="fields"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No se encontraron registros"
      class="position-relative"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>
      </template>

      <template #head(etapa)>
        <feather-icon
          icon="AirplayIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(etapa)="data">
        <b-avatar
          :id="`cis-row-${data.item.idControlIngresoSalida}`"
          size="32"
          :variant="data.item.etapa === 0 ? 'light-success' : 'light-primary'"
        >
          <feather-icon
            v-if="data.item.seccion === 'CLIENTE_DESPACHO'"
            icon="UserCheckIcon"
          />
          <feather-icon
            v-if="data.item.seccion === 'CISTERNAS_ABASTECEDORAS'"
            icon="TruckIcon"
          />
          <feather-icon
            v-if="data.item.seccion === 'TRASLADO_DEPOSITO'"
            icon="LogOutIcon"
          />
          <feather-icon
            v-if="data.item.seccion === 'VENTA'"
            icon="DollarSignIcon"
          />
        </b-avatar>
        <b-tooltip
          :target="`cis-row-${data.item.idControlIngresoSalida}`"
          placement="top"
        >
          <p class="mb-0">
            TIPO
          </p>
          <p class="mb-0">
            {{ data.item.seccion }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Individuo -->
      <template #cell(individuo)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bolder d-block text-nowrap font-small-3">
            {{ data.item.individuo }}
          </span>
          <template v-if="data.item.seccion === 'CLIENTE_DESPACHO'">
            <b-badge
              v-for="detalle in data.item.dataSeccionCD"
              :key="`detalle-${detalle.idDetalleClienteDespacho}`"
              pill
              variant="light-warning"
            >
              {{ detalle.producto.presentacion.descripcion }} - {{ detalle.producto.presentacion.marca.descripcion }} [Cantidad {{ detalle.cantidadIngresa }}]
            </b-badge>
          </template>
          <template v-if="data.item.seccion === 'CISTERNAS_ABASTECEDORAS'">
            <b-badge
              :href="`${baseURL}api-erpunigas/api/v1/multimedia/byUrl?isthumb=false&url=${data.item.dataSeccionAC ? data.item.dataSeccionAC.fotoTicketBalanza : undefined }`"
              target="_blank"
              pill
              variant="light-danger"
            >
              PESO BALANZA {{ data.item.dataSeccionAC ? data.item.dataSeccionAC.cantidadInicialKg : '0' }} KG
            </b-badge>
          </template>
        </b-media>
      </template>

      <template #cell(fechaIngreso)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-small-3 font-weight-bolder">
              <feather-icon icon="CalendarIcon" />
              {{ data.item.fechaIngreso }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.observacionIngreso }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(fechaSalida)="data">
        <div class="d-flex align-items-center">
          <div v-if="data.item.fechaSalida">
            <div class="font-small-3 font-weight-bolder">
              <feather-icon icon="CalendarIcon" />
              {{ data.item.fechaSalida }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.observacionSalida }}
            </div>
          </div>
          <div
            v-else
            style="text"
          >
            <b-badge
              pill
              variant="light-success"
            >
              Pendiente
            </b-badge>
          </div>
        </div>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Send Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ from }} a {{ to }} de {{ of }} registros</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="page"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import config from '@/services/config'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,

    vSelect,
    flatPickr,
  },
  setup() {
    const isBusy = ref(false)
    const items = ref([])
    const fields = ref([
      { key: 'idControlIngresoSalida', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'etapa', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
      { key: 'individuo', label: 'Individuo', sortable: true },
      { key: 'fechaIngreso', label: 'Ingreso', sortable: true, thClass: 'text-center', tdClass: 'text-justify' },
      { key: 'fechaSalida', label: 'Salida', sortable: true, thClass: 'text-center', tdClass: 'text-justify' },
      { key: 'acciones' },
    ])
    const seccionesFilter = [
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Ruta',
        value: 'VENTA',
      },
      {
        text: 'Cisternas',
        value: 'CISTERNAS_ABASTECEDORAS',
      },
      {
        text: 'Clientes',
        value: 'CLIENTE_DESPACHO',
      },
      {
        text: 'Depósito',
        value: 'TRASLADO_DEPOSITO',
      },
    ]
    const configDP = ref({
      enableTime: false,
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: 'd/m/Y',
    })
    const page = ref(1)
    const perPage = ref(10)
    const sortBy = ref('idControlIngresoSalida%7CDESC')
    const query = ref('')
    const fechaFilter = ref('')
    const totalRows = ref(0)
    const totalItems = ref(0)
    const totalPage = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const pageOptions = ref([10, 20, 50])
    const seccionFilterSel = ref('')
    const { baseURL } = config

    const listarRegistros = async () => {
      isBusy.value = true
      await store.dispatch('vigilancia/CONTROLIS_FIND_ALL', {
        query: query.value,
        page: page.value,
        limit: perPage.value,
        sortBy: sortBy.value,
        fecha: fechaFilter.value,
        seccion: seccionFilterSel.value,
      })
        .then(response => {
          if (response) {
            items.value = response.items
            totalRows.value = response.totalRows
            totalItems.value = response.totalItems
            totalPage.value = response.totalPage
            from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
            to.value = perPage.value * (page.value - 1) + totalRows.value
            of.value = totalItems.value
          }
        })
      isBusy.value = false
    }

    watch([page, perPage, query, seccionFilterSel, fechaFilter], () => {
      listarRegistros()
    })

    listarRegistros()

    return {
      seccionFilterSel,
      seccionesFilter,

      avatarText,

      isBusy,
      sortBy,
      page,
      perPage,
      query,
      items,
      fields,
      fechaFilter,
      listarRegistros,
      totalRows,
      totalItems,
      totalPage,
      from,
      to,
      of,
      pageOptions,
      configDP,
      baseURL,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
